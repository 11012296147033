import React, { useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { ApplicationStatus } from "../Constant/onboardingData";
import { Info, GetUserStatus } from "../Api/onBoarding";
import LoadingScreen from "../Screens/LoadingScreen/LoadingScreen";
import { AppContext } from "../Context/Context";
import { Routes } from "../Constant/Routes/Routes";
import TagManager from "react-gtm-module";

interface PersonalinfodataProps {
  firstName: string;
  lastName: string;
  email: string;
}

const PrivateRoutes = ({ path, component }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    userId,
    setFirstName,
    setLastName,
    setEmailAddress,
    setToken,
    setApplicationStatus,
  } = React.useContext<any>(AppContext);
  const history = useHistory();
  const user_id: any = sessionStorage.getItem("userId");
  const investorType: any = sessionStorage.getItem("investorType");
  const checkToken = sessionStorage.getItem("token");

  React.useEffect(() => {
    if (user_id && checkToken) {
      checkFlow().finally(() => setIsLoading(false));
    } else {
      history.push("/", { step: 1 });
    }
    const tagManagerArgs = {
      gtmId: "GTM-P2J57HX",
      events: {
        sendUserInfo: user_id,
        Platform: "Web Onboarding",
      },
    };
    TagManager.initialize(tagManagerArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const setUserDatatoContext = (personalinfodata: PersonalinfodataProps) => {
    setFirstName(personalinfodata.firstName);
    setLastName(personalinfodata.lastName);
    setEmailAddress(personalinfodata.email);
    sessionStorage.setItem("email", JSON.stringify(personalinfodata.email));
    setToken(sessionStorage.getItem("token"));
  };

  const checkFlow = async () => {
    const data = await GetUserStatus.fetchMainData(user_id);

    const applicationStatus = data.message.application_status;
    setApplicationStatus(applicationStatus);
    try {
      const { message = {} } = await Info.GetUserDetails();
      if (message.logout || message.detail === "Token not found.") {
        history.push("/", { step: 1 });
      }
      const {
        data: { personalinfodata = {} },
      } = message;
      setUserDatatoContext(personalinfodata);
      if (applicationStatus === ApplicationStatus.REJECTED) {
        history.replace(Routes.REJECT_SCREEN);
        setIsLoading(false);
      } else if (message.data.is_agree) {
        history.replace(Routes.HOME_SCREEN);
        setIsLoading(false);
      } else if (applicationStatus === ApplicationStatus.APPROVED) {
        history.replace(Routes.DASHBOARD);
        setIsLoading(false);
      } else {
        if (message.success) {
          const {
            data: {
              personalinfodata = {},
              kycinfodata = {},
              addressinfodata = {},
              accreditedinvestordata = {},
              businessentitydata = {},
            },
          } = message;
          // to set user info so we can use it later for other screens
          //  and should not call api again
          setUserDatatoContext(personalinfodata);
          if (Object.keys(businessentitydata).length) {
            return history.replace(Routes.FINALIZE_ONBOARDING);
          } else if (Object.keys(accreditedinvestordata).length) {
            if (investorType === "0" || investorType === "4") {
              return history.replace(Routes.FINALIZE_ONBOARDING);
            } else {
              return history.replace(Routes.ENTITY_BUSINESS);
            }
          } else if (Object.keys(kycinfodata).length) {
            return history.replace(Routes.ACCREDITED_DOC);
          } else if (
            Object.keys(addressinfodata).length &&
            addressinfodata.docFile !== null
          ) {
            return history.replace(Routes.IDENTIFICATION);
          } else if (personalinfodata.first_name.length > 0) {
            return history.replace(Routes.ADDRESS_VERIFICATION);
          } else {
            return history.replace(Routes.PERSONAL_INFO_SCREEN);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return history.replace(Routes.PERSONAL_INFO_SCREEN);
    }
  };

  const Component = component;
  const checkUserLogin = sessionStorage.getItem("userId");

  return (
    <Route
      path={path}
      render={() => {
        if (isLoading) {
          return <LoadingScreen />;
        } else if (!checkUserLogin) {
          <Redirect to={Routes.MAIN} />;
        } else {
          return <Component />;
        }
      }}
    />
  );
};

export default PrivateRoutes;
